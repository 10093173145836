import React from 'react';
import Layout from '../components/layout/layout';
import './404.scss';

export default function Page404(props) {
  let userLang = 'en';
  if (typeof navigator !== 'undefined') {
    userLang = navigator.language;// || navigator.userLanguage;
  }
  let errorMessage = '404 - Requested page not found';
  userLang = userLang.substring(0, 2).toLowerCase();
  if (userLang === 'de') {
    userLang = 'de';
    errorMessage = '404 - Seite nicht gefunden';
  } else {
    userLang = 'en';
  }
  //let currentPath = props.location.pathname;
  return (
    <Layout language={userLang} title={errorMessage} description="page not found" path="/404" hasLanguageCounterpart={false}>
      404 - page not found
    </Layout>
  )
}
